/* body {
    background-color: #F2F2F2;
    color: white;
    font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    margin: 0;
    box-sizing: border-box;
} */

:root {
    --map-info-width: 400px;
    --menu-bar-height: 56px;
    --footer-height: 144px;
    --max-mobile-width: 480px;
    --btn-color-pink-light: #fbd0cd;
    --btn-color-pink-dark: #f7aba5;
    --btn-color-green-light: #00d6b7;
    --btn-color-green-dark: #35c3c1;
    --btn-color-yellow-light: #ffd357;
    /* --btn-color-yellow-light: #ffc445; */
    --btn-color-yellow-dark: #fda720;
    --btn-color-yellow-text: #fda720;
    --btn-color-pink: #f6c9c9;
    --btn-color-purple: #5020fd;
    --btn-color-blue: #bbe9ef;
    --btn-color-blue-2: #6ea6fd;
    --btn-color-blue-3: #6395E3;
    --btn-color-light-blue: #93d9df;
    --color-bootstrap-blue: #007bff;
}

.menubar-background {
    z-index: 99;
    position: fixed;
    display: block;
    height: 56px;
    width: 100%;
    /* background-color: #f8f9fa; */
    background-color: white;
}

.title {
    float: right;
    padding: 16px;
    padding-right: 52px;
    font-size: 1.25rem;
    letter-spacing: 0.05rem;
    text-align: center;
}

.title-icon {
    z-index: 9;
    position: fixed;
    width: 36px;
    height: 36px;
    right: 10px;
    top: 10px;
    float: left;
    transition: 0.5s ease-in-out;
}

#hambmenu {
    z-index: 9;
    position: fixed;
    width: 26px;
    left: 15px;
    top: 13px;
    float: left;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

#menu-clicker {
    position: fixed;
    content: "";
    height: 56px;
    width: 56px;
    cursor: pointer;
    z-index: 10;
}

#hambmenu span:nth-child(1) {
    top: 0px;
}

#hambmenu span:nth-child(2), #hambmenu span:nth-child(3) {
    top: 10px;
}

#hambmenu span:nth-child(4) {
    top: 20px;
}

#hambmenu.isopen span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
    opacity: 0;
}

#hambmenu.isopen span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#hambmenu.isopen span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#hambmenu.isopen span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
    opacity: 0;
}

#menu {
    z-index: 999;
    position: fixed;
    /* height: calc(100vh - 56px); */
    height: 100%;
    width: 240px;
    background-color: white;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    box-shadow: 0 -1px 24px rgba(0, 0, 0, 0.4);
}

#menu-background {
    /* Make sure the menu background is on top of everything else except the menu */
    z-index: 998;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.3);
    top: 0;
}

#menu ul {
    margin-top: 0px;
    padding: 0px;
    color: black;
    list-style-type: none;
    text-align: left;
}

#menu li:last-child {
    border-bottom: none;
}

#menu.isopen {
    -webkit-transform: none;
    transform: none;
}

#menu .menu-item-li {
    display: block;
    width: 100%;
    /* color: #6c757d; */
    color: black;
    padding: 15px;
    font-size: 1.2em;
    text-decoration: none;
}

#menu .active-menu-item-li {
    display: block;
    width: 100%;
    color: var(--btn-color-yellow-text);
    padding: 15px;
    font-size: 1.2em;
    text-decoration: none;
    background-color: #f8f9fa;
}

#menu .menu-item-li-fa-svg {
    height: 1em;
    padding-bottom: 4px;
    padding-right: 12px;
}

#menu li:hover {
    background-color: #f8f9fa;
}

@media screen and (max-width: 480px) {
    .menubar span {
        font-size: 1.2em;
    }
}

#menu .drawer-user-info {
    /* background: #f8f9fa; */
    max-width: 100%;
    height: auto;
    /* padding: 25px 30px 25px 30px; */
    padding: 40px 20px 40px 20px;
    display: flex;
    flex-direction: row;
}

#menu .user-thumbnail {}

#menu .user-thumbnail img {
    width: 160px;
    border-radius: 80px;
}

#menu .user-details {
    width: 100%;
}

#menu .user-name {
    color: black;
    /* font-size: 0.85rem;
    font-weight: 700; */
    margin: 0;
    margin-bottom: auto;
}

#menu .drawer-title {
    background: #f8f9fa;
    max-width: 100%;
    height: auto;
    text-align: center;
    margin: 0;
}

#menu .menu-divider {
    border-top: 1px solid #e6e6e6;
}