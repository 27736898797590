/*
Current grey: f8f9fa
Another grey: F5F6F7

fdd258
fda416
fba010
*/

:root {
    --content-container-max-width: 720px;
    --map-info-width: 400px;
    --menu-bar-height: 56px;
    --footer-height: 144px;
    --max-mobile-width: 480px;
    --btn-color-pink-light: #fbd0cd;
    --btn-color-pink-dark: #f7aba5;
    --btn-color-green-light: #00d6b7;
    --btn-color-green-dark: #35c3c1;
    --btn-color-yellow-light: #ffd357;
    /* --btn-color-yellow-light: #ffc445; */
    --btn-color-yellow-dark: #fda720;
    --btn-color-yellow-text: #fda720;
    --btn-color-pink: #f6c9c9;
    --btn-color-purple: #5020fd;
    --btn-color-blue: #bbe9ef;
    --btn-color-blue-2: #6ea6fd;
    --btn-color-blue-3: #6395E3;
    --btn-color-light-blue: #93d9df;
    --color-bootstrap-blue: #007bff;
    --color-grey-1: rgb(227, 227, 227);
    --color-grey-2: #e6e6e6;
}

/* MapboxGL location finder button override */

.mapboxgl-ctrl-bottom-left {
    top: 14px!important;
    right: 12px!important;
    left: auto!important;
}

/* Override default p */

p {
    /* font-size: 0.85rem; */
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 0.75rem;
}

.link-yellow:link {
    color: var(--btn-color-yellow-text);
}

.link-yellow:visited {
    color: var(--btn-color-yellow-text);
}

.link-yellow:hover {
    color: var(--btn-color-yellow-text);
}

.link-yellow:active {
    color: var(--btn-color-yellow-text);
}

/* Bootstrap input blue outline override */

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
    border-color: var(--btn-color-yellow-dark);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
    outline: 0 none;
}

/* Used to highlight font awesome icons */

.fa-highlight {
    color: #00d6b7;
}

/* Bootstrap primary button override background */

.btn-custom {
    color: #fff;
    border: none;
    background: -webkit-linear-gradient(left, var(--btn-color-yellow-light), var(--btn-color-yellow-dark));
    background: -o-linear-gradient(left, var(--btn-color-yellow-light), var(--btn-color-yellow-dark));
    background: linear-gradient(to right, var(--btn-color-yellow-light), var(--btn-color-yellow-dark));
}

/* This is used to remove navbar button color highlight on click and border */

.btn-custom:focus {
    outline: none!important;
    box-shadow: none!important;
}

.btn-custom:hover {
    outline: none!important;
    box-shadow: none!important;
}

/* a {
    color: #6c757d!important;
} */

/* Add this class to body to prevent scrolling */

.no-scroll {
    overflow: hidden;
}

.rating-fa-svg {
    height: 20px;
    width: 20px;
    padding-bottom: 4px;
}

/*===== This removes the ugly text field blue outline when clicked in chrome and bootstrap =====*/

textarea:hover, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active, button:hover, label:focus, .btn:active, .btn.active {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

/*============================================*/

/*===== This removes the ugly button outline in chrome and bootstrap =====*/

.btn:focus {
    outline: none;
}

button:focus {
    outline: 0;
}

/*============================================*/

/* This is used to remove navbar button color highlight on click and border */

.navbar-toggler:focus, .navbar-toggler:active {
    outline: none!important;
    box-shadow: none!important;
}

.navbar-light .navbar-toggler {
    border: none!important;
}

/* This removed link underline decoration */

a, a:hover, a:active, a:visited, a:focus {
    text-decoration: none;
}

/*=============== Layout ===============*/

.layout-container {
    /* This is used to make sure the main content wrapped in layout doesn't go under nav bar */
    padding-top: var(--menu-bar-height);
}

.content-container {
    margin: auto;
    max-width: var(--content-container-max-width);
}

/*============================================*/

/*=============== Footer ===============*/

.page-footer {
    bottom: 0;
}

/*============================================*/

/*=============== Login ===============*/

.login-content-container {
    max-height: calc(100vh - var(--footer-height) - var(--menu-bar-height));
}

.login-img {
    max-height: 60vh;
}

.fb-login-button {
    width: 80%;
    max-width: 360px;
}

/*============================================*/

/*=============== Map specific ===============*/

.map-container {
    position: fixed;
    width: calc(100vw - var(--map-info-width));
    height: 100vh;
    left: var(--map-info-width);
}

.map-container-mobile {
    position: fixed;
    width: 100vw;
    /* height: calc(100vh - 135px); */
    height: calc(50vh + var(--menu-bar-height));
}

#map {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
}

.map-info-container {
    position: absolute;
    width: var(--map-info-width);
    min-height: 100%;
    left: 0;
    top: 0;
    background-color: white;
    box-shadow: 0 -1px 24px rgba(0, 0, 0, 0.4);
    padding-top: var(--menu-bar-height);
}

.map-info-container-mobile {
    background-color: white;
    /* top: calc(100vh - 135px); */
    /* top: calc(50vh + var(--menu-bar-height)); */
    margin-top: calc(50vh + var(--menu-bar-height));
    min-height: calc(50vh - var(--menu-bar-height));
    z-index: 10;
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 12px;
}

.map-card-link a {
    color: var(--btn-color-yellow-text);
}

.map-search-bar {
    z-index: 9;
    position: absolute;
    height: auto;
    width: calc(100% - 112px);
    left: 56px;
    /* width: calc(100% - 16px);
    left: 8px;
    padding-left:48px; */
    top: 10px;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    /* background-color: #f8f8f8; */
    background-clip: padding-box;
    /* border: 0px solid #ced4da; */
    /* border-radius: 18px; */
    /* border-color: var(--btn-color-yellow-light); */
    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)!important;
}

.map-fa-svg {
    height: 1.2em;
    width: 1.2em;
    padding-bottom: 0.2em;
    /* color: #007bff; */
}

.map-search-btn {
    z-index: 9;
    position: absolute;
    height: auto;
    width: 160px;
    left: calc(50% - 80px);
    top: 60px;
    border-radius: 18px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)!important;
    color: black;
    border: none;
    background: white;
}

.boba-details-footer-wrapper {
    height: calc(50vh - var(--menu-bar-height));
    padding: auto;
}

.detail-up-btn {
    position: absolute;
    width: 40px;
    height: 4px;
    border-radius: 2px;
    top: 0.75rem;
    left: calc(50% - 20px);
    background: var(--color-grey-2);
}

/*============================================*/

/*=============== Boba Details ===============*/

/* Boba Detail Tabs */

.detail-tabs {
    display: flex;
    justify-content: space-evenly;
    -webkit-box-align: center;
    align-items: center;
    height: auto;
    user-select: none;
    overflow-x: hidden;
    background: white;
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.detail-tab-li-selected {
    position: relative;
    display: block;
    line-height: 52px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: var(--btn-color-yellow-dark);
    /* background: -webkit-linear-gradient(left, var(--btn-color-yellow-light), var(--btn-color-yellow-dark));
    background: -o-linear-gradient(left, var(--btn-color-yellow-light), var(--btn-color-yellow-dark));
    background: linear-gradient(to right, var(--btn-color-yellow-light), var(--btn-color-yellow-dark)); */
}

.detail-tab-li {
    position: relative;
    display: block;
    line-height: 52px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: var(--btn-color-yellow-dark);
    cursor: pointer;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.detail-tab-li::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 22px;
    right: -1px;
    top: 0px;
    display: block;
    margin: 15px 0px;
    background: rgb(227, 227, 227);
}

#details-page-to-top-button {
    height: 1.75em;
    width: 100%;
}

/* #details-page-to-top-button:active {
    background: #f8f8f8;
}

#details-page-to-top-button:hover {
    background: #f8f8f8;
} */

.details-up-btn-fa-svg {
    color: var(--btn-color-yellow-text);
    height: 1.5rem;
    width: 1.5rem;
    padding-bottom: 0.2rem;
}

.delivery-img {
    height: 50px;
    width: 50px;
    border-radius: 10px;
}

/* Used to size rating font awesome icons because we disabled the
autoloading of fontawesome css */

.details-fa-svg {
    color: var(--btn-color-yellow-text);
    height: 1rem;
    width: 1rem;
    padding-bottom: 0.2rem;
}

.hours-of-operation-fa-svg {
    height: 20px;
    width: 20px;
    padding-bottom: 4px;
}

.boba-details-btn {
    color: var(--btn-color-yellow-text) !important;
    border-color: var(--btn-color-yellow-light) !important;
}

.boba-details-btn:active {
    background-color: #f8f8f8 !important;
    color: var(--btn-color-yellow-dark) !important;
    border-color: var(--btn-color-yellow-dark) !important;
}

.boba-details-btn a {
    color: inherit;
}

.details-recommended-fa-container {
    /* float: right; */
}

.details-recommended-fa-svg {
    color: var(--btn-color-yellow-text);
    height: 1.5rem;
    width: 1.5rem;
    margin-top: -0.3rem;
}

.detail-btn-container-mobile {
    width: 100vw;
}

.detail-btn-container-desktop {
    width: var(--content-container-max-width);
}

.detail-btn-container {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 12px;
    z-index: 400;
    list-style: none;
    margin: 0px;
    padding: 0px;
    background: white;
}

/* Detail buttons */

.detail-btn-li {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    height: 64px;
    text-align: center;
    cursor: pointer;
}

.detail-btn-container-fa-svg {
    color: var(--btn-color-yellow-text);
    padding-bottom: 0.2rem;
    width: 1.5rem;
    height: 1.5rem;
}

.detail-btn-container-icon-img {
    color: black;
    width: 1.5rem;
    height: 1.5rem;
}

.detail-btn-container li::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 22px;
    right: -2px;
    top: 0px;
    display: block;
    margin: 26px 0px;
    background: rgb(227, 227, 227);
}

.detail-btn-text {
    color: black;
    font-size: 11px;
    font-weight: 500;
    margin-top: 1px;
}

.detail-btn-inactive {
    color: var(--color-grey-1)!important;
    color: black!important;
    opacity: 0.2;
}

.boba-detail-info-section {
    margin-bottom: 64px;
}

/*============================================*/

/*=============== Hours of operation ===============*/

.hours-of-operation-table-btn {
    color: black;
    padding: 0;
    border: none;
    background: none;
    font-size: 13px;
    margin-top: 0;
    /* margin-bottom: 0.75rem; */
}

.hours-of-operation-table {
    /* Width of hours of operation table */
    width: 275px;
    margin: auto;
    cursor: pointer;
    font-size: 13px;
    margin-top: 0;
    /* margin-bottom: 0.75rem; */
}

/*============================================*/

/*=============== Sorting Panel ===============*/

#sorting-panel {
    /* Make sure the sorting panel is on top of everything else */
    z-index: 99;
    position: fixed;
    width: 100%;
    height: 100%;
    background: white;
    top: 0;
    margin: auto;
    max-width: 720px;
}

.sorting-panel-btn {
    width: 36px;
    height: 36px;
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .075)!important;
    border-radius: 18px;
    color: #fff;
    border: none;
    background: white;
    color: black;
}

.sorting-panel-fa-svg {
    height: 20px;
    width: 20px;
    margin-top: -4px;
    margin-left: -4px;
}

.sort-button {
    /* Override the sort list button outline */
    border: none;
}

/* Override default bootstrap list group item */

.list-group-item.active {
    z-index: 2;
    color: #fff;
    border: none;
    background: -webkit-linear-gradient(left, var(--btn-color-yellow-light), var(--btn-color-yellow-dark));
    background: -o-linear-gradient(left, var(--btn-color-yellow-light), var(--btn-color-yellow-dark));
    background: linear-gradient(to right, var(--btn-color-yellow-light), var(--btn-color-yellow-dark));
}

/*============================================*/

/*============== Mobile Card =============*/

/* image thumbnail */

.thumb {
    display: block;
    width: 100%;
    margin: 0;
}

/* Style to article Author */

.by-author {
    font-style: italic;
    line-height: 1.3;
    color: #aab6aa;
}

.main-list {
    padding-left: .5em;
}

.main-list .media {
    padding-bottom: 1.1em;
    border-bottom: 1px solid #e8e8e8;
}

/*============================================*/

/*=============== Boba List ===============*/

/* Style for the search bar */

.search-bar {
    display: block;
    width: 100%;
    height: 36px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #f8f8f8;
    background-clip: padding-box;
    /* border-color: var(--btn-color-yellow-light); */
    border: 0px solid #ced4da;
    /* border-radius: 18px; */
}

#search-bar-container {
    /* Make sure the search bar is on top of the boba list */
    z-index: 9;
    position: fixed;
    height: auto;
    width: 100%;
    max-width: 720px;
    background: white;
    padding: 15px;
    padding-top: 0px;
    padding-bottom: 10px;
}

/* Style for the boba list */

#poi-list {
    padding-top: 53px;
}

/*============================================*/

/*============== Boba List Item =============*/

.item-container {
    position: relative;
}

.item-card {
    display: flex;
    flex-direction: row;
    background: #fff;
    /* box-shadow: 0 0.1875rem 1.5rem rgba(0, 0, 0, 0.2); */
    /* border-radius: 5px; */
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important;
}

/* .item-card:hover {
    border: 1px solid var(--btn-color-yellow-light)!important;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

.item-card:active {
    border: 1px solid var(--btn-color-yellow-light)!important;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
} */

.list-item-fa-svg {
    color: var(--btn-color-yellow-text);
    height: 1.4em;
    width: 1.4em;
    padding-bottom: 0.4em;
}

.item-thumbnail {
    height: auto;
    width: auto;
    margin-right: 10px;
}

.item-thumbnail img {
    border: none;
    border-style: none;
    display: block;
    object-fit: cover;
    width: 85px;
    height: 85px;
}

/* Hide thumbnail image div without display */

.item-thumbnail img:not([src]) {
    /* display: none; */
    /* content: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'); */
}

.item-details {
    width: 100%;
}

.item-distance {
    float: right;
    /* font-weight: 400; */
    font-size: 0.75rem;
    margin: 0;
    margin-bottom: auto;
}

.item-title {
    color: black;
    font-size: 0.85rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: auto;
}

.item-title a {
    color: black;
}

.item-address {
    font-size: 0.75rem;
    margin: 0;
    margin-bottom: auto;
}

.item-address a {
    color: var(--btn-color-yellow-text);
}

.item-tags {
    font-size: 0.75rem;
    margin: 0;
    margin-bottom: auto;
}

.item-hours {
    font-size: 0.75rem;
    margin: 0;
}

.recommended-fa-container {
    position: absolute;
    top: -5px;
    right: 10px;
    display: block;
    width: 25px;
    height: 25px;
    float: right;
    z-index: 2;
    color: #fff;
    border: none;
    border-radius: 3px;
    text-align: center;
    background: -webkit-linear-gradient(bottom, var(--btn-color-yellow-light), var(--btn-color-yellow-dark));
    background: -o-linear-gradient(bottom, var(--btn-color-yellow-light), var(--btn-color-yellow-dark));
    background: linear-gradient(to bottom, var(--btn-color-yellow-light), var(--btn-color-yellow-dark));
}

.recommended-fa-svg {
    color: white;
    height: 1.2em;
    width: 1.2em;
    padding-bottom: 0.2em;
}

.recommended-avatar {
    position: absolute;
    top: -12px;
    right: 10px;
    display: block;
    width: 25px;
    height: 25px;
    float: right;
    z-index: 2;
    color: #fff;
    border: none;
    border-radius: 3px;
    text-align: center;
}

.recommended-avatar .rating-img {
    width: 30px;
}

/*============================================*/

/*============== Poi Submission Form =============*/

.poi-submission-form-btn {
    width: 100%;
    max-width: 360px;
}

.error-message {
    color: red;
}

/*============================================*/

/*============== Review List Item =============*/

.review-border-bottom {
    border-bottom: 1px solid #e6e6e6;
}

.review-border-top {
    border-top: 1px solid #e6e6e6;
}

.review-list-item-fa-svg {
    height: 20px;
    width: 20px;
    padding-bottom: 4px;
}

.review-list-item-date {
    float: right;
    margin: 0;
    margin-bottom: auto;
}

.review-img-li {
    list-style-type: none;
}

.square {
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
}

.review-img-row {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    margin-bottom: 0.75rem;
}

/*============================================*/

/*============== Place Photos Section =============*/

.place-photos-row {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}

/*============================================*/

/*============== About Page =============*/

.about-image {
    height: 60vh;
}

@media screen and (max-width: 720px) {
    .about-image {
        max-width: 100%;
        height: auto;
    }
}

/*============================================*/

/*=============== Review Submission ===============*/

/* #review-submission-panel {
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    top: 0;
    overflow-y: scroll;
} */

/* #review-submission-panel {
    width: 100%;
    height: 100%;
} */

/*============================================*/

/*=============== Boba menu list ===============*/

.boba-menu-list-item-vote-container {
    /* margin-left: 10px; */
    width: 20px;
}

.boba-menu-list-item-vote-col-temp {
    font-size: 10px;
    text-align: center;
}

.boba-menu-list-item-vote-col {
    text-align: center;
    height: 15px;
}

.boba-menu-list-item-vote-row {
    text-align: center;
    width: auto;
}

.boba-menu-list-item-name {
    display: inline-block;
    width: 100%;
    text-transform: capitalize;
}

.boba-menu-list-item {
    display: inline-block;
}

.boba-menu-list-item-up-voted {
    color: var(--btn-color-yellow-text);
}

.boba-menu-item-thumbnail {
    height: auto;
    width: auto;
    margin-right: 10px;
}

.boba-menu-item-thumbnail img {
    border: none;
    border-style: none;
    display: block;
    object-fit: cover;
    width: 50px;
    height: 50px;
}

.boba-menu-item-card {
    display: flex;
    flex-direction: row;
    background: #fff;
    /* box-shadow: 0 0.1875rem 1.5rem rgba(0, 0, 0, 0.2); */
    /* border-radius: 5px; */
    overflow: hidden;
    /* margin-left: 15px;
    margin-right: 15px; */
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    /* box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important; */
}

/*============================================*/

/*================== Store Details Page ==================*/

.store-details-page {}

/*============================================*/